import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./LoginContainer.css";
import Navigator from "./navigator/navigator";
import DashboardList from "./DashboardList/DashboardList";
import CustomLiteDashboardList from "./customLiteDashboardList/customLiteDashboardList"
import DistributionKpiCustomLiteDashboard from "./distributionKpiCustomLiteDashboard/distributionKpiCustomLiteDashboard"
import PalletChargeCustomLiteDashboard from "./palletChargeCustomLiteDashboard/palletChargeCustomLiteDashboard"
import SalesForceInsightsCustomLiteDashboard from "./salesForceInsightsCustomLiteDashboard/salesForceInsightsCustomLiteDashboard"
import RocheDiagnosticsKpiReviewCustomLiteDashboard from "./rocheDiagnosticsKpiReviewCustomLiteDashboard/rocheDiagnosticsKpiReviewCustomLiteDashboard"
import ProDashboardList from "./proDashboardList/proDashboardList";
import AvantDashboardList from "./avantDashboardList/avantDashboardList";
import SalesForecastingDashboard from "./salesForecastingDashboard/salesForecastingDashboard";
import ConnectClient from "./connectClient/ConnectClient";
import ClientSalesDashboard from "./salesDashboard/ClientSalesDashboard";
import SalesDashboardBenchmarking from "./salesDashboardBenchmarking/salesDashboardBenchmarking"
import InventoryTracking from "./inventoryTracking/InventoryTracking";
import InventoryInsightDashboard from "./inventoryInsightDashboard/InventoryInsightDashboard";
import ContactUsPage from "./contactUs/ContactUs";
import HecOrderPendingCustomLiteDashboard from "./hecOrderPendingCustomLiteDashboard/hecOrderPendingCustomLiteDashboard";
import SIDNovoNordiskCustomLiteDashboard from "./sidNovoNordiskCustomLiteDashboard/sidNovoNordiskCustomLiteDashboard";
import SPDCKPICustomLiteDashboard from "./spdcKpiCustomLiteDashboard/spdcKpiCustomLiteDashboard";
import SCMCustomLiteDashboard from "./scmCustomLiteDashboard/scmCustomLiteDashboard";
import ProInventoryInsightDashboard from "./proInventoryInsightDashboard/proInventoryInsightDashboard";
import TorrentMyDashboard from "./torrentMyDashboard/torrentMyDashboard";
import AISCustomLiteDashboard from "./aisCustomLiteDashboard/aisCustomLiteDashboard";
import MarketInsightsDashboard from "./marketInsightsDashboard/marketInsightsDashboard";
import RochePharmaDashboard from "./rochePharmaDashboard/rochePharmaDashboard";
import axios from "axios";
import { REACT_APP_JAVA_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } from "./settings"

import { useMsal } from "@azure/msal-react";
import { ThreeDots } from 'react-loader-spinner'
import connectClientLogo from "./connectClientLogo.png";
import loaderBgImg from "./loaderBgImg.png";


function LoginContent() {

  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const config = {
    headers: {
      "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const [isLoaded, setLoaded] = useState(true);

  useEffect(() => {
    axios.post(
      `${REACT_APP_JAVA_BASE_URL}/connect-client/login/user/${userEmail}`, {},
      // `https://azsgcsschecapi01d.azure-api.net/connect-client/user/logout/${userEmail}`,
      config
    )
      .then(function (response) {

        sessionStorage.setItem('pbiAccess', response.data.pbiAccess)
        sessionStorage.setItem('btpAccess', response.data.btpAccess)
        sessionStorage.setItem('customAccess', response.data.customAccess)
        sessionStorage.setItem('proPbiAccess', response.data.proPbiAccess)
        sessionStorage.setItem('navigatorAccess', response.data.navigator)
        sessionStorage.setItem('inventoryPbiAccess', response.data.inventoryPbiAccess)
        sessionStorage.setItem('benchmarkingPbiAccess', response.data.benchmarkingPbiAccess)
        sessionStorage.setItem('distributionKpiCustomPbiAccess', response.data.distributionKpiCustomPbiAccess)
        sessionStorage.setItem('palletChargeCustomPbiAccess', response.data.palletChargeCustomPbiAccess)
        sessionStorage.setItem('salesForceInsightsCustomPbiAccess', response.data.salesForceInsightsCustomPbiAccess)
        sessionStorage.setItem('rocheDiagnosticsKpiReviewCustomPbiAccess', response.data.rocheDiagnosticsKpiReviewCustomPbiAccess)
        sessionStorage.setItem('salesForecastingPbiAccess', response.data.salesForecastingPbiAccess)
        sessionStorage.setItem('hecOrderPendingCustomPbiAccess', response.data.hecOrderPendingCustomPbiAccess)
        sessionStorage.setItem('novoNordiskCustomPbiAccess', response.data.novoNordiskCustomPbiAccess)
        sessionStorage.setItem('spdcKpiCustomPbiAccess', response.data.spdcKpiCustomPbiAccess)
        sessionStorage.setItem('proIidAccess', response.data.proIidAccess)
        sessionStorage.setItem('scmCustomPbiAccess', response.data.scmCustomPbiAccess)
        sessionStorage.setItem('torrentMyPbiAccess', response.data.torrentMyPbiAccess)
        sessionStorage.setItem('aisCustomPbiAccess', response.data.aisCustomPbiAccess)
        sessionStorage.setItem('marketInsightsPbiAccess', response.data.marketInsightsPbiAccess)
        sessionStorage.setItem('rochePharmaPbiAccess', response.data.rochePharmaPbiAccess)
        setLoaded(false);


      })
      .catch(function (error) {
        sessionStorage.setItem('pbiAccess', false)
        sessionStorage.setItem('btpAccess', false)
        sessionStorage.setItem('customAccess', false)
        sessionStorage.setItem('proPbiAccess', false)
        sessionStorage.setItem('navigatorAccess', false)
        sessionStorage.setItem('inventoryPbiAccess', false)
        sessionStorage.setItem('benchmarkingPbiAccess', false)
        sessionStorage.setItem('distributionKpiCustomPbiAccess', false)
        sessionStorage.setItem('palletChargeCustomPbiAccess', false)
        sessionStorage.setItem('salesForceInsightsCustomPbiAccess', false)
        sessionStorage.setItem('rocheDiagnosticsKpiReviewCustomPbiAccess', false)
        sessionStorage.setItem('salesForecastingPbiAccess', false)
        sessionStorage.setItem('hecOrderPendingCustomPbiAccess', false)
        sessionStorage.setItem('novoNordiskCustomPbiAccess', false)
        sessionStorage.setItem('spdcKpiCustomPbiAccess', false)
        sessionStorage.setItem('proIidAccess', false)
        sessionStorage.setItem('scmCustomPbiAccess', false)
        sessionStorage.setItem('torrentMyPbiAccess', false)
        sessionStorage.setItem('aisCustomPbiAccess', false)
        sessionStorage.setItem('marketInsightsPbiAccess', false)
        sessionStorage.setItem('rochePharmaPbiAccess', false)
        setLoaded(true);
        console.error(error);
      });
  }, [])

  return (
    <>
      {isLoaded ?
        <div style={{ textAlign: "center", position: "absolute", height: "100%", width: "100%", backgroundImage: `url(${loaderBgImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
          <div style={{ margin: "0", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <h1>Login To</h1>
            <img
              src={connectClientLogo}
              alt="ConnectClientLogo"
              style={{ maxWidth: "300px", height: "auto" }}
            />
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#AB1E34"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ display: "inherit" }}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div> :
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/connect_client/navigator"
              component={Navigator}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_standard"
              component={DashboardList}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom"
              component={CustomLiteDashboardList}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/pro_dashboard"
              component={ProDashboardList}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/avant_dashboard"
              component={AvantDashboardList}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_standard/sales_insight_dashboard"
              component={ClientSalesDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_standard/inventory_insight_dashboard"
              component={InventoryInsightDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/distribution_kpi"
              component={DistributionKpiCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/pallet_charge"
              component={PalletChargeCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/sales_force_insights"
              component={SalesForceInsightsCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/roche_diagnostics_scm_dashboard"
              component={RocheDiagnosticsKpiReviewCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/hec_order_pending_dashboard"
              component={HecOrderPendingCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/novo_nordisk_dashboard"
              component={SIDNovoNordiskCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/spdc_kpi_dashboard"
              component={SPDCKPICustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_standard/scm_roche_diagnostics_dashboard"
              component={SCMCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/scm_roche_pharma_dashboard"
              component={RochePharmaDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/lite_custom/vietnam_daily_sales_and_stock_report"
              component={AISCustomLiteDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/pro_dashboard/sales_insight_dashboard_with_benchmarking"
              component={SalesDashboardBenchmarking}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/pro_dashboard/inventory_insight_dashboard"
              component={ProInventoryInsightDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/pro_dashboard/torrent_my_dashboard"
              component={TorrentMyDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/pro_dashboard/market_insights_dashboard"
              component={MarketInsightsDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/navigator/avant_dashboard/sales_forecasting_dashboard"
              component={SalesForecastingDashboard}
            ></Route>
            <Route
              exact
              path="/connect_client/administrative_operation"
              component={InventoryTracking}
            ></Route>
            <Route
              exact
              path="/contact_us"
              component={ContactUsPage}
            ></Route>
            <Route path="/" component={ConnectClient}></Route>
          </Switch>
        </BrowserRouter>
      }
    </>
  );
}

export default LoginContent;
