import React, { useEffect, useState } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useMsal } from "@azure/msal-react";

import { REACT_APP_JAVA_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } from "../settings"
import "./scmCustomLiteDashboard.css";
import axios from "axios";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import InfoBar from "../resusableComponent/InfoBar";

const back = "back";
const subHeading = "SCM Dashboard - Roche Diagnostics";

function SCMCustomLiteDashboard(props) {
  
  const [embedUrl, setEmbedUrl] = useState("");
  const [embedToken, setEmbedToken] = useState("");
  const [reportId, setReportId] = useState("");
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  const onClickBack = (path) => {
    props.history.push(path);
  };

  const dashBoardNameForClient =  (window.location.hostname === "devconnectclient.vnbizbox.com" || window.location.hostname === "qaconnectclient.vnbizbox.com" || window.location.hostname === "connectclient.vnbizbox.com")
    ? "scmVietnam":"SCM";

  const config = {
    headers: {
      "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      dashBoardName: dashBoardNameForClient
    },
  };

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({name: 'SCM Dashboard - Roche Diagnostics'});
    }
    axios
      .get(
        `${REACT_APP_JAVA_BASE_URL}/connect-client/token/powerbi/${userEmail}`,
        config
      )
      .then(function (response) {
        
        setEmbedToken(response.data.embeddToken);
        setEmbedUrl(response.data.embeddUrl);
        setReportId(response.data.id);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  const exportVisual = async () => {
    if (!window.report) {
      
      return;
    }
    await window.report.print();
  };

  return (
    <>
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator/lite_standard"
        />
        <InfoBar onClickBack={onClickBack} pagePath="Analytical Solution / Sales Insight Dashboard" dashboard='true' exportVisual={exportVisual} />
      </div>
      <PowerBIEmbed
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual and qna
          id: reportId,
          embedUrl: embedUrl,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: true,
                visible: false,
              },
              pageNavigation: {
                visible: false
              }
            },
            bars: {
              actionBar: {
                visible: false
              }
            },
            background: models.BackgroundType.Default,
          },
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                
              },
            ],
            [
              "rendered",
              function () {
                
              },
            ],
            [
              "error",
              function (event) {
                
              },
            ],
          ])
        }
        cssClassName={"Embed-container"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
      {/* <button onClick={() => exportVisual()}>Export Report</button> */}
      <BottomInfoBar />
    </>
  );
}

export default SCMCustomLiteDashboard;
