import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import Cards from "../resusableComponent/Cards";
import IconButton from "@mui/material/IconButton";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import "./customLiteDashboardList.css";
import SingleActionDialog from "../resusableComponent/SingleActionDialog";
import navigationImage from "./navigationPage.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMsal } from "@azure/msal-react";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
const back = "back";
const subHeading = "Lite - Custom";

function CustomLiteDashboardList(props) {
  const headerDistributionKPI = "Distribution KPI - Thailand";
  const headerPalletCharge = "Pallet Charge - Thailand";
  const headerSalesForceInsights = "Sales Force Insights - Thailand";
  const headerRocheDiagnosticsKPIReview = "Roche Diagnostics SCM Dashboard";
  const hecOrderPending = "HEC Order Pending Dashboard";
  const novoNordisk = "Market Insights - Novo Nordisk SG";
  const spdckpi = "SPDC KPI Dashboard";
  const ais = "Daily Sales & Stock Report - Vietnam";
  const rochePharma = "SCM Dashboard - Roche Pharma";

  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Lite - Custom' });
    }
  }, []);

  const analyticsIcon = (
    <IconButton aria-label="DashBoard" sx={{ padding: "2px" }}>
      <InsertChartIcon sx={{ color: "#AB1E34", fontSize: "40px" }} />
    </IconButton>
  );

  const checkAccess = (cardType) => {
    if (cardType === "distributionkpipowerpbi") {
      if (sessionStorage.getItem("distributionKpiCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Distribution KPI - Thailand Dashboard");
        return false;
      }
    }
    if (cardType === "palletchargepowerpbi") {
      if (sessionStorage.getItem("palletChargeCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Pallet Charge - Thailand Dashboard");
        return false;
      }
    }
    if (cardType === "salesforceinsightspowerbi") {
      if (sessionStorage.getItem("salesForceInsightsCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Sales Force Insights - Thailand Dashboard");
        return false;
      }
    }
    if (cardType === "rochediagnosticskpireviewpowerbi") {
      if (sessionStorage.getItem("rocheDiagnosticsKpiReviewCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Roche Diagnostics SCM Dashboard");
        return false;
      }
    }
    if (cardType === "hecorderpendingpowerbi") {
      if (sessionStorage.getItem("hecOrderPendingCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to HEC Order Pending Dashboard");
        return false;
      }
    }
    if (cardType === "novonordiskpowerbi") {
      if (sessionStorage.getItem("novoNordiskCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Market Insights - Novo Nordisk SG Dashboard");
        return false;
      }
    }
    if (cardType === "spdckpipowerbi") {
      if (sessionStorage.getItem("spdcKpiCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to SPDC KPI Dashboard");
        return false;
      }
    }
    if (cardType === "aispowerbi") {
      if (sessionStorage.getItem("aisCustomPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Daily Sales & Stock Report - Vietnam");
        return false;
      }
    }
    if (cardType === "rochepharmapowerbi") {
      if (sessionStorage.getItem("rochePharmaPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to SCM Dashboard - Roche Pharma");
        return false;
      }
    }
  };

  const onClickCard = (path) => {
    props.history.push(path);
  };

  const onClickBack = (path) => {
    props.history.push(path);
  };

  return (
    <>
      <SingleActionDialog open={singleOpen} msg={msg} setOpen={setSingleOpen} />
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator"
        />
      </div>
      <Box
        style={{
          backgroundImage: `url(${navigationImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        sx={{ display: { xs: 'none', md: 'flex' } }}>
        <div style={{ height: "45vh" }}></div>
      </Box>
      <Grid
        container
        direction="row"
      >
        {
          sessionStorage.getItem("distributionKpiCustomPbiAccess") === "true" ?
            <Cards
              header={headerDistributionKPI}
              isImage="false"
              //subHeader={subHeaderAnalytics}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/distribution_kpi"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="distributionkpipowerpbi"
              onClickCard={onClickCard}
              hoverMsg="Daily Distribution KPI - Thailand for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("palletChargeCustomPbiAccess") === "true" ?
            <Cards
              header={headerPalletCharge}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/pallet_charge"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="palletchargepowerpbi"
              onClickCard={onClickCard}
              hoverMsg="Daily Pallet Charge - Thailand for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("salesForceInsightsCustomPbiAccess") === "true" ?
            <Cards
              header={headerSalesForceInsights}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/sales_force_insights"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="salesforceinsightspowerbi"
              onClickCard={onClickCard}
              hoverMsg="Daily Sales Force Insights - Thailand for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("rocheDiagnosticsKpiReviewCustomPbiAccess") === "true" ?
            <Cards
              header={headerRocheDiagnosticsKPIReview}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/roche_diagnostics_scm_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="rochediagnosticskpireviewpowerbi"
              onClickCard={onClickCard}
              hoverMsg="Monthly Roche Diagnostics SCM Dashboard for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("hecOrderPendingCustomPbiAccess") === "true" ?
            <Cards
              header={hecOrderPending}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/hec_order_pending_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="hecorderpendingpowerbi"
              onClickCard={onClickCard}
              hoverMsg="Monthly HEC Order Pending Dashboard for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("novoNordiskCustomPbiAccess") === "true" ?
            <Cards
              header={novoNordisk}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/novo_nordisk_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="novonordiskpowerbi"
              onClickCard={onClickCard}
              hoverMsg="Monthly Market Insights - Novo Nordisk SG Dashboard for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("spdcKpiCustomPbiAccess") === "true" ?
            <Cards
              header={spdckpi}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/spdc_kpi_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="spdckpipowerbi"
              onClickCard={onClickCard}
              hoverMsg="Monthly SPDC KPI Dashboard for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("aisCustomPbiAccess") === "true" ?
            <Cards
              header={ais}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/vietnam_daily_sales_and_stock_report"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="aispowerbi"
              onClickCard={onClickCard}
              hoverMsg="Daily Sales & Stock Report - Vietnam for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("rochePharmaPbiAccess") === "true" ?
            <Cards
              header={rochePharma}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/lite_custom/scm_roche_pharma_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="rochepharmapowerbi"
              onClickCard={onClickCard}
              hoverMsg="Monthly SCM Dashboard - Roche Pharma for better Business planning"
            /> : <></>
        }

      </Grid>
      <br />
      <BottomInfoBar />
    </>
  );
}

export default CustomLiteDashboardList;
